import { Dispatch } from 'react'

import { ExpandableConfig } from 'antd/lib/table/interface'

export enum EOrderByFullText {
  ascend = 'ascend',
  descend = 'descend'
}

export enum EOrderBy {
  asc = 'asc',
  desc = 'desc'
}
export interface ISortWeakInTable {
  sortBy?: string | any
  orderBy?: EOrderByFullText
}

export interface IPaginationType {
  current: number
  pageSize: number
}

export interface IFetchParams {
  page?: number
  keyword?: string
  sortBy?: string | any
  orderBy?: string
}

export interface IPagingResponse {
  actualPageSize: number
  currentPage: number
  totalPages: number
  totalRows: number
}

export enum TableColumnSortDirectionEnum {
  ASCEND = 'ascend',
  DESCEND = 'descend'
}

export interface ITableColumn {
  fixed?: string
  title: string | any
  key: string
  width?: number | string
  minWidth?: number
  alignRight?: boolean
  sorter?: boolean
  className?: string
  ellipsis?: boolean
  render?: any
  sortDirections?: TableColumnSortDirectionEnum[]
  editable?: boolean
}

export interface ITableColumnCustom {
  title: JSX.Element
  key: string
  width?: number
  alignRight?: boolean
  sorter?: boolean
  className?: string
  render?: any
  sortDirections?: TableColumnSortDirectionEnum[]
  editable?: boolean
}

export enum FILTER_TYPE {
  EQUAL = 'AEQ',
  GREATE_THAN_OR_EQUAL = 'AGE',
  GREATE_THAN = 'AGT',
  LESS_THAN_OR_EQUAL = 'ALE',
  LESS_THAN = 'ALT',
  NOT_EQUAL = 'ANE',
  BETWEEN = 'ABT',
  LIKE = 'ALK',
  NULL = 'AIN',
  NOT_NULL = 'ANN',
  NOT_LIKE = 'ANL',
  CONTAIN = 'ACT',
  UNSET = ''
}

export interface IFilter {
  field: string
  value?: any
  type: FILTER_TYPE
}

export interface IProps {
  columns: ITableColumn[]
  expandRowOptions?: {
    expandable?: ExpandableConfig<any>
    idRowKey: string
    expandIconColumnIndex?: number
    expandedRowRender?: any
    expandableHubSummary?: boolean
  }
  isSelectTable?: boolean
  fixedSelection?: boolean
  responseKeyData?: string
  searchField?: IFilter
  showSearchFilter?: boolean
  initSortConfig?: {
    sortBy?: string
    orderBy?: EOrderByFullText
  }
  fixedFilter?: IFilter[]
  fixedPayload?: any // {}
  externalParams?: any // {}
  isShowErrNotifyIfFetchErr?: boolean
  isDisablePaging?: boolean
  isDisableSorting?: boolean
  data?: any[]
  searchOptions?: {
    isHideSearchArea?: boolean
    isHideBtn?: boolean
    isDisableBtn?: boolean
    isDisableInputText?: boolean
    btnText?: string
    btnIcon?: JSX.Element
    handleOnClickBtn?: Dispatch
    buttonColor?: string
    placeHolder?: string
    linkText?: ''
    linkDirection?: ''
    isHideLink?: true
    isHideDropDown?: true
    isDisableLink?: false
    isDisableDropdown?: false
    listOptionDropdown?: any[]
    handleOnSelectChangeDropdown?: Dispatch
    isHideSecondButton?: boolean
    btnSecondText?: string
    btnSecondIcon?: JSX.Element
    btnSecondColor?: string
    isDisableBtnSecond?: boolean
    handleOnClickBtnSecond?: Dispatch
    isHideInputSearch?: boolean
    isHideContent?: boolean
    styleDropDown?: any
    content?: any
  }
  tdOptions?: {
    verticalAlign: string
  }
  isForceManualFetch?: boolean
  scrollConfig?:
    | boolean
    | {
        x?: number | string
        y?: number | string
      }
  Fetch?: Dispatch
  pageSizeSet?: number
  pageSizeDefault?: number
  handleOnFetchSuccess?: (data: any) => void
  handleOnFetchError?: (err: any) => void
  handleOnChangeRowSelect?: (
    selectedRowKeys: Key[],
    selectedRows: any[]
  ) => void
  handleOnResetFormAdvancedSearch?: () => void
  tableSummary?: boolean
  defaultValueDropdown?: any
  hideSelectAll?: boolean
  isDisableSelect?: boolean
  reloadAfterSelectDropdown?: boolean
  listFieldSearch?: IFieldSearchObject[]
  isCustomForProgress?: boolean
  isEditTable?: boolean
  EditableCell?: any
  dataEdit?: any[]
  setDataEdit?: (data: any[]) => void
}

export interface IImperativeHandle {
  resetToFirstPage: () => void
  refresh: () => void
  fetch: () => void
  setCurrentSort: (sortConfig: ISortWeakInTable) => void
  getHeadCellsWidth: () => number[]
  headerWidths: number[]
  tableId: string
  data: {
    searchKeyword: string
  }
  setLoading: (isLoading: boolean) => void
  dataWithUuid: any
}
